import {SearchContextProvider} from '@backstage/plugin-search-react';
import React from 'react';
import {Grid, makeStyles} from "@material-ui/core";
import {Content, Header, InfoCard, Page} from "@backstage/core-components";
import {
    HomePageCompanyLogo,
    HomePageStarredEntities,
    HomePageToolkit,
    TemplateBackstageLogo,
    TemplateBackstageLogoIcon
} from "@backstage/plugin-home";
import {HomePageSearchBar} from "@backstage/plugin-search";
import {BazaarOverviewCard} from '@backstage/plugin-bazaar';
import {
    AnnouncementsCard,
    AnnouncementsTimeline,
    NewAnnouncementBanner
} from "@procore-oss/backstage-plugin-announcements";
// import {ShouldIDeployCard} from 'backstage-plugin-should-i-deploy';
// import {HomePageXkcdComic, XkcdComicCard} from 'backstage-plugin-xkcd';

const useStyles = makeStyles(theme => ({
    searchBarInput: {
        maxWidth: '60vw',
        margin: 'auto',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '50px',
        boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
        borderStyle: 'none'
    }
}));

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
    svg: {
        width: 'auto',
        height: 100,
    },
    path: {
        fill: '#7df3e1',
    },
}));

export const HomePage = () => {
    const classes = useStyles();
    const {svg, path, container} = useLogoStyles();
    return (
        <SearchContextProvider>
            <Page themeId="home">
                <Header title="Home" />
                <Content>

                    <Grid item md={12}>
                        <NewAnnouncementBanner />
                    </Grid>
                    <Grid container justifyContent="center" spacing={6}>
                        <HomePageCompanyLogo
                            className={container}
                            logo={<TemplateBackstageLogo classes={{svg, path}}/>}
                        />
                        <Grid container item xs={12} justifyContent='center'>
                            <HomePageSearchBar
                                InputProps={{
                                    classes: {
                                        root: classes.searchBarInput,
                                        notchedOutline: classes.searchBarOutline
                                    }
                                }}
                                placeholder="Search"
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={6}>
                                <HomePageStarredEntities/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <HomePageToolkit
                                    tools={Array(8).fill({
                                        url: '#',
                                        label: 'link',
                                        icon: <TemplateBackstageLogoIcon/>,
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InfoCard title="Announcements">
                                    <AnnouncementsTimeline/>
                                </InfoCard>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InfoCard title="Composable Section">
                                     placeholder for content
                                    <div style={{height: 370}}/>
                                </InfoCard>
                            </Grid>
                            {/*TODO*/}
                            {/*<Grid item xs={ 12 } md={ 6 }>*/}
                            {/*    <HomePageStackOverflowQuestions*/}
                            {/*        requestParams={ {*/}
                            {/*            tagged: 'backstage',*/}
                            {/*            site: 'stackoverflow',*/}
                            {/*            pagesize: 5,*/}
                            {/*        } }*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                        </Grid>

                        <Grid item md={6}>
                            <AnnouncementsCard max={3} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BazaarOverviewCard order='latest'/>
                        </Grid>

                        <Grid item xs={12}>
                            <BazaarOverviewCard title='My Orgs Projects' order='random' fullWidth fullHeight/>
                        </Grid>

                        {/*(TODO}*/}
                        {/*<Grid item md={3} xs={6} style={{height: "500px"}}>*/}
                        {/*    <XkcdComicCard/>*/}
                        {/*</Grid>*/}

                        {/*<CustomHomepageGrid>*/}
                        {/*    <HomePageXkcdComic/>*/}
                        {/*</CustomHomepageGrid>*/}
                        {/*<Grid item>*/}
                        {/*    <ShouldIDeployCard title='Choose your title!' timeZone="America/Sao_Paulo"/>*/}
                        {/*</Grid>*/}
                    </Grid>

                </Content>
            </Page>
        </SearchContextProvider>
    );
};
