import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CategoryIcon from '@material-ui/icons/Category';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {Settings as SidebarSettings, UserSettingsSignInAvatar,} from '@backstage/plugin-user-settings';
import {SidebarSearchModal} from '@backstage/plugin-search';
import {
    GitHubIcon,
    Link,
    Sidebar,
    sidebarConfig,
    SidebarDivider,
    SidebarGroup,
    SidebarItem,
    SidebarPage,
    SidebarScrollWrapper,
    SidebarSpace,
    SidebarSubmenu,
    SidebarSubmenuItem,
    useSidebarOpenState
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import StorefrontIcon from '@material-ui/icons/Storefront';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BuildIcon from '@material-ui/icons/Build';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useApp} from "@backstage/core-plugin-api";
import {RequirePermission} from "@backstage/plugin-permission-react";
import {devToolsAdministerPermission} from '@backstage/plugin-devtools-common';
import {NewReleases} from "@material-ui/icons";
// import Timelapse from '@material-ui/icons/Timelapse';
// import {timeSaverPermission} from "@tduniec/backstage-plugin-time-saver-common";

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const {isOpen} = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link to="/" underline="none" className={classes.link} aria-label="Home">
                {isOpen ? <LogoFull/> : <LogoIcon/>}
            </Link>
        </div>
    );
};

export const Root = ({children}: PropsWithChildren<{}>) => (
    <SidebarPage>
        <Sidebar>
            <SidebarLogo/>
            <SidebarGroup label="Search" icon={<SearchIcon/>} to="/search">
                <SidebarSearchModal/>
            </SidebarGroup>
            <SidebarDivider/>
            <SidebarGroup label="Menu" icon={<MenuIcon/>}>
                <SidebarItem icon={HomeIcon} to="/" text="Home"/>
                <SidebarItem icon={NewReleases} to="/announcements" text="Announcements"/>
                <SidebarItem icon={CategoryIcon} to="catalog" text="Category">
                    <SidebarSubmenu title="Catalog">
                        <SidebarSubmenuItem
                            title="Domains"
                            to="catalog?filters[kind]=domain"
                            icon={useApp().getSystemIcon('kind:domain')}
                        />
                        <SidebarSubmenuItem
                            title="Systems"
                            to="catalog?filters[kind]=system"
                            icon={useApp().getSystemIcon('kind:system')}
                        />
                        <SidebarSubmenuItem
                            title="Components"
                            to="catalog?filters[kind]=component"
                            icon={useApp().getSystemIcon('kind:component')}
                        />
                        <SidebarSubmenuItem
                            title="APIs"
                            to="catalog?filters[kind]=api"
                            icon={useApp().getSystemIcon('kind:api')}
                        />
                        <SidebarDivider/>
                        <SidebarSubmenuItem
                            title="Resources"
                            to="catalog?filters[kind]=resource"
                            icon={useApp().getSystemIcon('kind:resource')}
                        />
                        <SidebarDivider/>
                        <SidebarSubmenuItem
                            title="Groups"
                            to="catalog?filters[kind]=group"
                            icon={useApp().getSystemIcon('kind:group')}
                        />
                        <SidebarSubmenuItem
                            title="Users"
                            to="catalog?filters[kind]=user"
                            icon={useApp().getSystemIcon('kind:user')}
                        />
                    </SidebarSubmenu>
                </SidebarItem>
                <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs"/>
                <SidebarItem icon={LibraryBooks} to="docs" text="Docs"/>
                <SidebarItem icon={CreateComponentIcon} to="create" text="Create..."/>
                {/* End global nav */}
                <SidebarDivider/>
                <SidebarItem icon={PlaylistPlayIcon} to="playlist" text="Playlists"/>
                <SidebarItem icon={LiveHelpIcon} to="qeta" text="Q&A"/>
                <SidebarItem icon={StorefrontIcon} to="bazaar" text="Bazaar"/>
                <SidebarItem icon={DashboardIcon} to="bulletin-board" text="Bulletin Board"/>
                <RequirePermission
                    permission={devToolsAdministerPermission}
                    errorPage={<></>}>
                    <SidebarItem icon={BuildIcon} to="devtools" text="DevTools"/>
                </RequirePermission>
                <SidebarItem icon={BuildIcon} to="entity-validation" text="Validator"/>
                <SidebarItem icon={CheckCircleIcon} to="fossa" text="FOSSA"/>
                <SidebarItem icon={GitHubIcon} to="github-codespaces" text="Codespaces"/>

                {/*<RequirePermission permission={timeSaverPermission} errorPage={<></>}>*/}
                {/*    <SidebarItem*/}
                {/*        icon={Timelapse}*/}
                {/*        to="time-saver"*/}
                {/*        text="TimeSaver"*/}
                {/*    />*/}
                {/*</RequirePermission>*/}
                <SidebarScrollWrapper>
                    <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar"/>
                </SidebarScrollWrapper>
            </SidebarGroup>
            <SidebarSpace/>
            <SidebarDivider/>
            <SidebarGroup
                label="Settings"
                icon={<UserSettingsSignInAvatar/>}
                to="/settings"
            >
                <SidebarSettings/>
            </SidebarGroup>
        </Sidebar>
        {children}
    </SidebarPage>
);
