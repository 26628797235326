import React from 'react';
import {Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage, catalogImportPlugin,} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import { apis } from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {AlertDisplay, OAuthRequestDialog, ProxiedSignInPage, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {HomePage} from "./components/home/HomePage";
import {HomepageCompositionRoot} from "@backstage/plugin-home";
import {PlaylistIndexPage, PlaylistPage} from '@backstage/plugin-playlist';
import {QetaPage} from '@drodil/backstage-plugin-qeta';
import {AnnouncementsPage} from '@procore-oss/backstage-plugin-announcements';
import {BazaarPage} from '@backstage/plugin-bazaar';
import {BulletinBoardPage} from 'backstage-plugin-bulletin-board';
import {DevToolsPage} from '@backstage/plugin-devtools';
import {devToolsAdministerPermission} from '@backstage/plugin-devtools-common';
import {EntityValidationPage} from '@backstage/plugin-entity-validation';
import {FossaPage} from '@backstage/plugin-fossa';
// import {GithubCodespacesPage} from '@adityasinghal26/plugin-github-codespaces';
// import {LdapAuthFrontendPage} from '@immobiliarelabs/backstage-plugin-ldap-auth';
import {githubAuthApiRef} from "@backstage/core-plugin-api";
// import {ShouldIDeployPage} from 'backstage-plugin-should-i-deploy';
// import {timeSaverPermission} from '@tduniec/backstage-plugin-time-saver-common';
// import {TimeSaverPage} from '@tduniec/backstage-plugin-time-saver';
// import {XkcdPage} from 'backstage-plugin-xkcd';

const app = createApp({
    components: {
        SignInPage: props => (
            <>
                <SignInPage
                    {...props}
                    auto
                    providers={['guest',
                        {
                            id: 'github-auth-provider',
                            title: 'GitHub',
                            message: 'Sign in using GitHub',
                            apiRef: githubAuthApiRef,
                        },
                        // <LdapAuthFrontendPage {...props} provider="ldap" />
                    ]}
                    title="Select a sign-in method"
                    align="center"
                />
                <ProxiedSignInPage {...props} provider="oauth2Proxy" />
                {/*TODO*/}
                {/*<LdapAuthFrontendPage {...props} provider="ldap"/>*/}
            </>
        ),
    },
    apis,
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<HomepageCompositionRoot/>}>
            <HomePage/>
        </Route>
        <Route path="/catalog" element={<CatalogIndexPage/>}/>
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}
        >
            {entityPage}
        </Route>
        <Route path="/playlist" element={<PlaylistIndexPage/>}/>
        <Route path="/playlist/:playlistId" element={<PlaylistPage/>}/>
        <Route path="/qeta" element={<QetaPage title="Questions"/>}/>
        <Route path="/announcements" element={<AnnouncementsPage/>}/>
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>;
        <Route path="bazaar" element={<BazaarPage/>}/>
        <Route path="/bulletin-board" element={<BulletinBoardPage/>}/>
        <Route path="/devtools" element={<DevToolsPage/>}/>
        <Route path="/devtools"
               element={
                   <RequirePermission permission={devToolsAdministerPermission}>
                       <DevToolsPage/>
                   </RequirePermission>
               }
        />
        <Route path="/entity-validation" element={<EntityValidationPage/>}/>
        <Route path="/fossa" element={<FossaPage/>}/>
        {/*<Route path="/github-codespaces" element={<GithubCodespacesPage/>}/>*/}
        <Route path="/playlist" element={<PlaylistIndexPage/>}/>
        <Route path="/playlist/:playlistId" element={<PlaylistPage/>}/>
        {/*<Route path="/should-i-deploy" element={<ShouldIDeployPage/>}/>*/}
        {/*<Route*/}
        {/*    path="/time-saver"*/}
        {/*    element={*/}
        {/*        <RequirePermission permission={timeSaverPermission}>*/}
        {/*            <TimeSaverPage/>*/}
        {/*        </RequirePermission>*/}
        {/*    }*/}
        {/*/>*/}
        {/*<Route path="/xkcd" element={<XkcdPage/>}/>*/}
        <Route path="/docs" element={<TechDocsIndexPage/>}/>
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}
        >
            <TechDocsAddons>
                <ReportIssue/>
            </TechDocsAddons>
        </Route>
        <Route path="/create" element={<ScaffolderPage/>}/>
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route
            path="/tech-radar"
            element={<TechRadarPage width={1500} height={800}/>}
        />
        <Route
            path="/catalog-import"
            element={
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage/>
                </RequirePermission>
            }
        />
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage/>}/>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
