import {ScmAuth, ScmIntegrationsApi, scmIntegrationsApiRef,} from '@backstage/integration-react';
import {AnyApiFactory, configApiRef, createApiFactory,} from '@backstage/core-plugin-api';

// import {MatomoAnalytics} from '@janus-idp/backstage-plugin-analytics-module-matomo';
import {extraTips, tipsConfigRef} from "@dweber019/backstage-plugin-tips";

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    // TODO
    // createApiFactory({
    //     api: analyticsApiRef,
    //     deps: {configApi: configApiRef},
    //     factory: ({configApi}) => MatomoAnalytics.fromConfig(configApi),
    // }),
    createApiFactory({
        api: tipsConfigRef,
        deps: {},
        factory: () => {
            return {
                tips: [...extraTips],
            };
        },
    }),
    ScmAuth.createDefaultApiFactory(),
];
